import { useMenu } from '@/providers/menu';
import { usePopper } from '@/providers/popper';
import {
	Add as AddIcon,
	Check as CheckIcon,
	ContentCopy as ContentCopyIcon,
	Delete as DeleteIcon,
	DragIndicatorRounded as DragIndicatorRoundedIcon,
	ExpandMore as ExpandMoreIcon,
	PlaylistAddCheckRounded as PlaylistAddCheckRoundedIcon,
	SortByAlpha as SortByAlphaIcon,
} from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	MenuItem,
	Pagination,
	Stack,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { isEmpty, isEqual, orderBy, pick } from 'lodash-es';
import React, { Fragment, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Actions, { ActionProps } from '../actions';
import Loading from '../loading';
import PageSection from '../page/section';
import Sortable from '../sortable';
import type { EnhancedDisplayProps, EnhancedListProps } from './helpers';
import { _deleteRow, _handleSelectItem } from './helpers';

export const pageSizeOptions = [ 10, 25, 50, 100, 'All' ];

function EnhancedList<Item>( {
	title,
	pageSectionProps,
	data = [],
	extraData,
	setData,
	editable,
	sortable,
	selectable,
	onClick,
	loading,
	loadingComponent = <Loading/>,
	emptyComponent = (
		<Typography
			textAlign='center'
			color='text.secondary'
			py={1}>
			Nothing to display
		</Typography>
	),
	renderRow,
	renderPanel,
	removeEditing,
	removeDelete,
	addButtonProps,
	editButtonProps,
	moreActions,
	hasPagination = false,
	savePageSize,
	defaultPageSize,
	...props
}: EnhancedDisplayProps<Item> & EnhancedListProps<Item> ) {
	const theme = useTheme();
	const { showMenu } = useMenu();
	const { showPopper } = usePopper();
	const { t } = useTranslation();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	
	const [ sorting, setSorting ] = useState( false );
	const [ selecting, setSelecting ] = useState( false );
	const [ selectedItems, setSelectedItems ] = useState( [] );
	const [ sortField, setSortField ] = useState<string | null>( null );
	const [ pageIndex, setPageIndex ] = useState( 0 );
	const [ pageSize, setPageSize ] = useState( defaultPageSize ? defaultPageSize === 'All' ? data.length
			: defaultPageSize
		: pageSizeOptions[ 0 ] );
	const [ isAllSelected, setIsAllSelected ] = useState( defaultPageSize === 'All' );
	
	const numericPageSize = isAllSelected ? data.length : Number( pageSize );
	const pageCount = pageSize === 'All' ? 1 : Math.ceil( data.length / numericPageSize );
	const isDarkMode = theme.palette.mode === 'dark';
	const validPageCount = !isNaN( pageCount ) ? pageCount : 1;
	
	// Sorting logic (used to sort by what is passed in sortOptions)
	const sortedData = useMemo( () => {
		if ( !sortField ) return data;
		const sortFields = sortField.split( '-' );
		return orderBy( data, [ sortFields[ 0 ] ], [ sortFields[ 1 ] as 'desc' | 'asc' ] );
	}, [ data, sortField ] );
	
	const handlePageSizeChange = ( size ) => {
		if ( size === 'All' ) {
			setIsAllSelected( true );
			setPageSize( data.length );
		} else {
			setIsAllSelected( false );
			setPageSize( Number( size ) );
		}
		setPageIndex( 0 );
	};
	
	const paginatedData = useMemo( () => {
		if ( !hasPagination ) return sortedData;
		const startIndex = pageIndex * numericPageSize;
		const endIndex = Math.min( startIndex + numericPageSize, sortedData.length );
		return sortedData.slice( startIndex, endIndex );
	}, [ sortedData, pageIndex, numericPageSize, hasPagination ] );
	
	const dataItems = useMemo( () => {
		const totalSelected = selectedItems.length;
		const row = ( item, index, handle, selected ) => (
			<Fragment>
				{( removeEditing || sorting ) && Boolean( sortable ) && (
					<ListItemIcon sx={{ minWidth: 40 }}>
						<IconButton>
							<DragIndicatorRoundedIcon {...handle}/>
						</IconButton>
					</ListItemIcon>
				)}
				{renderRow( item, index, { selecting, sorting } )}
				{( removeEditing || sorting ) && !removeDelete && Boolean( editable )
					&& ( editable?.min ? data.length > editable.min : true )
					&& (
						<IconButton
							sx={{ p: .5, ml: .5 }}
							color='error'
							onClick={( e ) => {
								e.stopPropagation();
								_deleteRow( data, setData, editable, selectable, item, index, selected, totalSelected );
							}}>
							<DeleteIcon sx={{ fontSize: 20 }}/>
						</IconButton>
					)}
			</Fragment>
		);
		
		const panel = ( { item, index, handle }: { item, index, handle? } ) => {
			const selected = selectedItems.includes( item?.id ?? index );
			return renderPanel ? (
				<Accordion>
					<AccordionSummary
						className='listItem'
						expandIcon={<ExpandMoreIcon/>}
						onClick={() => {
							if ( selecting ) {
								_handleSelectItem( item.id, selectedItems, setSelectedItems );
							} else {
								onClick?.( item, index );
							}
						}}>
						{row( item, index, handle, selected )}
					</AccordionSummary>
					<AccordionDetails>
						{renderPanel( item, index )}
					</AccordionDetails>
				</Accordion>
			) : selectable || onClick ? (
				<ListItemButton
					disableGutters
					divider={index !== data.length - 1}
					selected={selected}
					className='listItem'
					onClick={() => {
						if ( selecting ) {
							_handleSelectItem( item.id, selectedItems, setSelectedItems );
						} else {
							onClick?.( item, index );
						}
					}}
					{...props.listItemButtonProps}>
					{row( item, index, handle, selected )}
				</ListItemButton>
			) : (
				<ListItem
					disableGutters
					divider={index !== data.length - 1}
					className='listItem'
					selected={selected}>
					{row( item, index, handle, selected )}
				</ListItem>
			);
		};
		
		return sorting ? (
			<Sortable
				items={sortedData as any}
				setItems={setData as any}
				renderItem={panel}
			/>
		) : !isEmpty( paginatedData ) ? paginatedData.map( ( item, index ) => (
			<Fragment key={index}>
				{panel( { item, index } )}
			</Fragment>
		) ) : null;
		
	}, [
		data,
		extraData,
		Boolean( editable ),
		sortable,
		removeEditing,
		sorting,
		selecting,
		selectedItems,
		paginatedData,
	] );
	const items = useMemo( () => {
		const buttons: Array<ActionProps | boolean> = [];
		const addButton = {
			name       : t( 'common:add' ),
			onClick    : async () => {
				editable.onAdd?.();
				editable.newData && setData?.( [ ...data, { ...await editable.newData() } ] );
			},
			buttonProps: {
				variant  : 'outlined',
				color    : isDarkMode ? 'primary' : 'alpha',
				startIcon: <AddIcon/>,
				sx       : { height: 28 },
				...addButtonProps,
			},
		};
		if ( !loading && ( editable || Boolean( sortable ) ) ) {
			
			if ( !sorting && !selecting && moreActions?.items ) {
				buttons.push( ...moreActions.items );
			}
			
			if ( !sorting && !selecting && ( editable?.max ? data.length < editable.max : true ) ) {
				buttons.push( addButton );
			}
			if ( Boolean( sortable ) && sortable.sortOptions && data.length > 1 && !selecting && !sorting ) {
				buttons.push( {
					name       : 'Sort by',
					onClick    : ( e ) => showPopper( ( { closePopper } ) => (
						<Fragment>
							{sortable.sortOptions.map( ( option ) => (
								<MenuItem
									key={option.field + option.order}
									selected={sortField === `${option.field}-${option.order}`}
									onClick={() => {
										const sortFieldString = `${option.field}-${option.order}`;
										setSortField( sortFieldString );
										const sortedData = orderBy( data, [ option.field ], [ option.order ] );
										setData?.( sortedData as Item[] );
										closePopper();
									}}>
									{option.label}
								</MenuItem>
							) )}
						</Fragment>
					), e.currentTarget ),
					buttonProps: {
						variant  : 'outlined',
						color    : isDarkMode ? 'primary' : 'alpha',
						startIcon: <SortByAlphaIcon/>,
						sx       : { height: 28 },
					},
				} );
			}
			if ( !removeEditing && Boolean( sortable ) && !selecting ) {
				buttons.push( {
					name       : sorting ? t( 'common:done' ) : t( 'common:sort' ),
					onClick    : () => {
						setSorting( !sorting );
						sortable?.onSort?.( !sorting );
					},
					buttonProps: {
						variant  : 'outlined',
						color    : isDarkMode ? 'primary' : 'alpha',
						startIcon: sorting ? <CheckIcon/> : <DragIndicatorRoundedIcon/>,
						sx       : { height: 28, animation: sorting && 'ripple2 1s linear infinite' },
						...editButtonProps,
					},
				} );
			}
			if ( selectable && !sorting && selecting ) {
				buttons.push( {
					name       : t( 'common:done' ),
					onClick    : () => {
						if ( selecting ) setSelectedItems( [] );
						setSelecting( !selecting );
					},
					buttonProps: {
						variant  : 'outlined',
						color    : isDarkMode ? 'primary' : 'alpha',
						startIcon: selecting ? <CheckIcon/> : <PlaylistAddCheckRoundedIcon/>,
						sx       : { height: 28, animation: selecting && 'ripple2 1s linear infinite' },
						...editButtonProps,
					},
				} );
			}
			
			if ( data.length && selectable && !sorting && !selecting ) {
				buttons.push( {
					name       : t( 'common:select' ),
					onClick    : () => {
						if ( selecting ) setSelectedItems( [] );
						setSelecting( !selecting );
					},
					buttonProps: {
						variant  : 'outlined',
						color    : isDarkMode ? 'primary' : 'alpha',
						startIcon: selecting ? <CheckIcon/> : <PlaylistAddCheckRoundedIcon/>,
						sx       : { height: 28 },
						...editButtonProps,
					},
				} );
			}
			if ( !sorting && selecting ) {
				buttons.push( {
					name       : t( 'common:copy-all' ),
					onClick    : async () => {
						editable.onCopyMultiple?.( selectedItems );
						if ( selecting ) setSelectedItems( [] );
						setSelecting( !selecting );
					},
					buttonProps: {
						variant  : 'outlined',
						color    : isDarkMode ? 'primary' : 'alpha',
						startIcon: <ContentCopyIcon/>,
						sx       : { height: 28 },
						disabled : !Boolean( selectedItems.length ),
					},
				} );
			}
			if ( !sorting && selecting ) {
				buttons.push( {
					name       : t( 'common:delete-all' ),
					onClick    : async () => {
						editable.onDeleteMultiple?.( selectedItems );
						if ( selecting ) setSelectedItems( [] );
						setSelecting( !selecting );
					},
					buttonProps: {
						variant  : 'outlined',
						color    : 'error',
						startIcon: <DeleteIcon/>,
						sx       : { height: 28 },
						disabled : !Boolean( selectedItems.length ),
					},
				} );
			}
			
			return buttons;
		}
		return undefined;
	}, [ data, editable, loading, sortable, selecting, sorting, selectedItems, sortField, isMobile ] );
	
	return (
		<PageSection
			primary={title}
			actions={items && (
				<Actions
					separated
					max={moreActions?.max || 0}
					items={items}
				/>
			)}
			{...pageSectionProps}>
			<List
				disablePadding
				sx={{
					'.MuiAccordionSummary-content' : { alignItems: 'center' },
					'.listItem'                    : removeEditing || sorting ? { px: 1 } : undefined,
					'.listItem:not(:first-of-type)': { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
					'.listItem:not(:last-of-type)' : { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 },
					'overflow'                     : 'hidden',
					'.MuiListItem-root'            : { p: 0 },
				}}
				{...props}>
				{loading || !data.length
					? <Box>{loading ? loadingComponent : emptyComponent}</Box>
					: dataItems}
			</List>
			{hasPagination && data.length >= 10 && (
				<Grid container spacing={1} mt={.5}>
					<Grid item xs={12} sm={6}>
						<Stack direction='row' alignItems='center'>
							<Typography>{t( 'common:rows-per-page' )}:</Typography>
							<Button
								component='li'
								variant='text'
								sx={{
									'color'       : 'text.primary',
									'fontWeight'  : 'normal',
									'minWidth'    : 32,
									'height'      : 32,
									'borderRadius': '16px',
									'ml'          : 1,
									'padding'     : '0 6px',
									'bgcolor'     : 'divider',
									':hover'      : {
										filter : 'brightness(80%)',
										bgcolor: 'divider',
									},
								}}
								onClick={( e ) => showMenu( ( { closeMenu } ) => (
									<Fragment>
										{pageSizeOptions.map( ( count, index ) => (
											<MenuItem
												key={index}
												selected={count === ( isAllSelected ? 'All' : pageSize )}
												onClick={() => {
													handlePageSizeChange( count );
													closeMenu();
												}}>
												{count}
											</MenuItem>
										) )}
										{Boolean( savePageSize ) && (
											<MenuItem
												onClick={() => {
													savePageSize?.( isAllSelected ? 'All' : Number( pageSize ) );
													closeMenu();
												}}>
												Save page size
											</MenuItem>
										)}
									</Fragment>
								), e.currentTarget, {
									anchorOrigin   : { vertical: 'bottom', horizontal: 'center' },
									transformOrigin: { vertical: 'top', horizontal: 'center' },
								} )}>
								{pageSize}
							</Button>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'end' } }}>
						<Pagination
							page={pageIndex + 1}
							count={validPageCount}
							onChange={( event, value ) => {
								setPageIndex( value - 1 );
							}}
						/>
					</Grid>
				</Grid>
			)}
		</PageSection>
	);
}

export default memo( EnhancedList, ( prevProps, nextProps ) =>
	isEqual( pick( prevProps, [ 'title', 'loading' ] ), pick( nextProps, [ 'title', 'loading' ] ) )
	&& Object.is( prevProps.data, nextProps.data )
	&& Object.is( prevProps.extraData, nextProps.extraData ) ) as typeof EnhancedList;
