import { styled, useTheme } from '@mui/material';
import { ComponentType, Fragment, HTMLAttributes, ReactHTML, ReactNode, useMemo } from 'react';
import type { ReactSortableProps } from 'react-sortablejs';
import { ReactSortable } from 'react-sortablejs';

const StyledReactSortable = styled( ReactSortable )( {} );

export default function Sortable<Item extends { id: string | number }>( {
	items,
	setItems,
	renderItem,
	...props
}: {
	items: Item[],
	setItems: ( items: Item[] ) => void,
	renderItem: ( props: { item: Item, index: number, handle: HTMLAttributes<any> } ) => ReactNode,
	tag?: ComponentType | keyof ReactHTML
} & Omit<ReactSortableProps<Item>, 'tag' | 'list' | 'setList'> ) {
	const theme = useTheme();
	
	const list = useMemo( () => items.map( ( item ) => ( { item } ) ), [ items ] );
	
	return (
		<StyledReactSortable
			list={list}
			setList={( items: { item: Item }[] ) => {
				setItems( items.map( ( { item } ) => item ) );
			}}
			handle='.sortable-handle'
			sx={{ '.sortable-ghost': { bgcolor: ( { palette } ) => `${palette.primary.main} !important` } }}
			animation={theme.transitions.duration.shorter}
			{...props as any}>
			{list.map( ( { item }, index ) => (
				<Fragment key={item.id}>
					{renderItem( { item, index, handle: { className: 'sortable-handle' } } )}
				</Fragment>
			) )}
		</StyledReactSortable>
	);
}
