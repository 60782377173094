import type {
	ButtonProps,
	ListItemButtonProps,
	ListProps,
	TableCellProps,
	TableContainerProps,
	TableProps,
} from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { ActionType } from '../graphqlTable';
import type { PageSectionProps } from '../page/section';

export type EnhancedDisplayProps<Item> = {
	title?: ReactNode,
	pageSectionProps?: PageSectionProps,
	data: Item[],
	// extra check for memo, needs to be memoized
	extraData?: any,
	// required if sortable is true
	setData?: ( items: Item[] ) => void,
	dataCount?: number,
	pageNumber?: number,
	pageActualSize?: number,
	setPageNumber?: ( pageNumber: number ) => void,
	setPageActualSize?: ( pageSize: number ) => void,
	editable?: {
		// required if setData is set
		newData?: () => Item | Promise<Item>,
		// required if setData is not set
		onAdd?: () => void,
		onDelete?: ( item: Item, index: number ) => void,
		onEdit?: ( item: Item, index: number ) => void,
		onCopyMultiple?: ( itemIds: string[] ) => void,
		onDeleteMultiple?: ( itemIds: string[] ) => void
	},
	hideAddIcon?: boolean,
	hideDelete?: ( item: Item ) => boolean,
	renderPanel?: ( item: Item, index: number ) => ReactNode,
	sortable?: Sortable,
	selectable?: boolean,
	onClick?: ( item: Item, index: number ) => void,
	onEdit?: ( item: Item, index: number ) => void, // optional used on edit icon
	loading?: boolean,
	loadingComponent?: ReactNode,
	emptyComponent?: ReactNode,
	hasPagination?: boolean,
	savePageSize?: ( size: number | string ) => void,
	defaultPageSize?: number | string
};

type Sortable = {
	onSort?: Dispatch<SetStateAction<boolean>>,
	sortOptions?: SortOption[]
};

export type SortOption = {
	field: string,
	order: 'asc' | 'desc',
	label: string
};

export type EnhancedListProps<Item> = {
	renderRow: ( item: Item, index: number, options?: { selecting: boolean, sorting: boolean } ) => ReactNode,
	removeEditing?: boolean,
	removeDelete?: boolean,
	addButtonProps?: ButtonProps,
	editButtonProps?: ButtonProps,
	listItemButtonProps?: ListItemButtonProps,
	moreActions?: ActionType,
	hasPagination?: boolean
} & Omit<ListProps, 'title' | 'onClick'>;

export type EnhancedTableProps<Item> = {
	headers: ReactNode[],
	footer?: ReactNode,
	columns: ( item: Item, index: number ) => ReactNode[],
	cellProps?: TableCellProps[],
	removeCellWrapper?: boolean,
	tableContainerProps?: TableContainerProps,
	hover?: boolean
} & Omit<TableProps, 'title' | 'onClick'>;

export function _deleteRow( data, setData, editable, selectable, item, index, selected, totalSelected ) {
	const _data = [ ...data ];
	const deleted = _data.splice( index, 1 );
	editable.onDelete?.( deleted[ 0 ], index );
	setData?.( _data );
	
	if ( selected && totalSelected <= selectable?.min ) {
		const newSelected = selectable.selected.filter( ( id ) => id !== ( item.id ?? index ) );
		const selected = _data.find( ( { id } ) => !newSelected.includes( id ) );
		if ( selected ) {
			newSelected.push( selected.id ?? index );
			selectable.setSelected( newSelected );
		}
	}
}

export function _handleSelectItem( itemId: string,
	selectedItems: string[],
	setSelectedItems: ( selectedItems: string[] ) => void ) {
	if ( !selectedItems.includes( itemId ) ) {
		setSelectedItems( [ ...selectedItems, itemId ] );
	} else {
		setSelectedItems( selectedItems.filter( ( id ) => id !== itemId ) );
	}
}
